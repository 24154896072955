import React from "react";
import Seo from "../../../components/Seo";
import Hero from "../../../views/Company/JoinUs/JobPage/Hero";
import JOBS from "../../../util/jobs";

const { slug, title, type, location, period } = JOBS[0];

const WebDevFrontEndJobPage = () => {
  return (
    <React.Fragment>
      <Seo title="Développeur web Front-end" />
      <Hero title={title} location={location!} period={period!} type={type} />
    </React.Fragment>
  );
};

export default WebDevFrontEndJobPage;
