import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { globalClasses } from "../../../../../components/layout/MainLayout/styles";
import { JoinUsHeroProps } from "./props";
import { classes, StyledSectionWrapper } from "./styles";
import clsx from "clsx";
import WorkOutlineRoundedIcon from "@mui/icons-material/WorkOutlineRounded";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";

const JoinUsHero = (props: JoinUsHeroProps) => {
  const { title, location, period, type } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledSectionWrapper
      padding="normal"
      className={globalClasses.innoGreyGradient}
    >
      <Container>
        <Grid container alignItems="center" spacing={3}>
          <Grid
            item
            sm={8}
            justifyContent={isMobile ? "center" : "flex-start"}
            container
            direction="column"
          >
            <Typography
              variant="h3"
              component="h1"
              align={isMobile ? "center" : "inherit"}
              gutterBottom
              className={classes.typo}
            >
              {title}
            </Typography>
            <Box display="flex">
              <Typography
                variant="body1"
                // align={isMobile ? "center" : "inherit"}
                gutterBottom
                className={clsx(classes.typo, classes.subtitleItem)}
              >
                <WorkOutlineRoundedIcon sx={{ mr: 1 }} />
                {type}
              </Typography>
              <Typography
                variant="body1"
                // align={isMobile ? "center" : "inherit"}
                gutterBottom
                className={clsx(classes.typo, classes.subtitleItem)}
              >
                <PinDropOutlinedIcon sx={{ mr: 1 }} />
                {location}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            sm={4}
            justifyContent={isMobile ? "center" : "flex-start"}
            container
            direction="column"
          >
            <Button
              // onClick={() => handleNavigate("/contact")}
              size={isMobile ? "medium" : "large"}
              className={classes.button}
              aria-label="Postuler"
            >
              Postuler
            </Button>
          </Grid>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default JoinUsHero;
