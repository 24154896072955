import SectionWrapper from "../../../../../components/layout/SectionWrapper";
import styled from "../../../../../util/styled";

const PREFIX = "JoinUsHero";

export const classes = {
  typo: `${PREFIX}-typo`,
  button: `${PREFIX}-button`,
  subtitleItem: `${PREFIX}-subtitleItem`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  [`& .${classes.typo}`]: {
    color: theme.palette.common.white,
  },

  [`& .${classes.subtitleItem}`]: {
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(),
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.iaPink.main,
    color: theme.palette.iaPink.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.iaPink.light,
      color: theme.palette.iaPink.dark,
    },
  },
}));
